import { Anchor } from "@config/anchors-config";
import { SupportedLanguages } from "@config/translation-config";
import { IconsSet } from "./icons";

type NavSubMenuItem = {
    href: Record<SupportedLanguages, string>;
    title: string;
};

export type NavSubMenu = {
    title: string;
    icon: IconsSet;
    iconSize: number;
    href: Record<SupportedLanguages, string>;
    items: NavSubMenuItem[];
};

type NavMenuItem = {
    title: string;
} & (
    | {
          anchor?: Anchor;
          href: Record<SupportedLanguages, string>;
          submenu?: never;
      }
    | {
          anchor?: never;
          href?: never;
          submenu: NavSubMenu[];
      }
);

interface NavMenuSection {
    sectionTitle: string;
    items: NavMenuItem[];
}

export const NAV_MENU: NavMenuItem[] = [
    {
        submenu: [
            {
                icon: "codeSolid",
                iconSize: 24,
                title: "navbar.development",
                href: { cs: "/vyvoj", en: "/en/development" },
                items: [
                    {
                        title: "navbar.webApps",
                        href: { cs: "/sluzby/webove-aplikace", en: "/en/services/web-applications" },
                    },
                    {
                        title: "navbar.clientZones",
                        href: { cs: "/sluzby/klientska-zona", en: "/en/services/customer-zone" },
                    },
                    { title: "navbar.eShops", href: { cs: "/vyvoj", en: "/en/development" } },
                    { title: "navbar.pwa", href: { cs: "/vyvoj", en: "/en/development" } },
                    { title: "navbar.mobileApps", href: { cs: "/vyvoj", en: "/en/development" } },
                    { title: "navbar.internalSystems", href: { cs: "/vyvoj", en: "/en/development" } },
                    { title: "navbar.integrations", href: { cs: "/vyvoj", en: "/en/development" } },
                    { title: "navbar.devOps", href: { cs: "/vyvoj", en: "/en/development" } },
                ],
            },
            {
                icon: "bullhornSolid",
                iconSize: 20,
                title: "navbar.marketing",
                href: { cs: "/produkt-a-marketing", en: "/en/product-and-marketing" },
                items: [
                    {
                        title: "navbar.performanceMarketing",
                        href: { cs: "/produkt-a-marketing", en: "/en/product-and-marketing" },
                    },
                    { title: "navbar.seo", href: { cs: "/sluzby/technicke-seo", en: "/en/services/technical-seo" } },
                    {
                        title: "navbar.contentMarketing",
                        href: { cs: "/produkt-a-marketing", en: "/en/product-and-marketing" },
                    },
                ],
            },
            {
                icon: "penRulerSolid",
                iconSize: 20,
                title: "navbar.design",
                href: { cs: "/ux-design", en: "/en/ux-design" },
                items: [
                    { title: "navbar.uxUiDesign", href: { cs: "/ux-design", en: "/en/ux-design" } },
                    { title: "navbar.branding", href: { cs: "/ux-design", en: "/en/ux-design" } },
                    { title: "navbar.testing", href: { cs: "/ux-design", en: "/en/ux-design" } },
                    { title: "navbar.uxAudit", href: { cs: "/ux-audit", en: "/en/ux-audit" } },
                ],
            },
            {
                icon: "tagSolid",
                iconSize: 20,
                title: "navbar.product",
                href: { cs: "/produkt-a-marketing", en: "/en/product-and-marketing" },
                items: [
                    {
                        title: "navbar.auditAndAnalysis",
                        href: { cs: "/produkt-a-marketing", en: "/en/product-and-marketing" },
                    },
                    { title: "navbar.startup", href: { cs: "/produkt-a-marketing", en: "/en/product-and-marketing" } },
                    {
                        title: "navbar.counselling",
                        href: { cs: "/it-poradenstvi", en: "/en/cto-as-a-service" },
                    },
                    {
                        title: "navbar.ecommerce",
                        href: { cs: "/produkt-a-marketing", en: "/en/product-and-marketing" },
                    },
                ],
            },
        ],
        title: "navbar.services",
    },
    { href: { cs: "/reference", en: "/en/reference" }, title: "navbar.reference" },
    { href: { cs: "/nas-tym", en: "/en/our-team" }, title: "navbar.team" },
    { href: { cs: "/blog", en: "/en/blog" }, title: "navbar.blog" },
    { href: { cs: "/kariera", en: "/en/hiring" }, title: "navbar.workWithUs" },
    { href: { cs: "/kontakt", en: "/en/contact" }, title: "navbar.contact" },
];

export const MOBILE_NAV_MENU: NavMenuSection[] = [
    {
        sectionTitle: "navbar.services",
        items: [
            { href: { cs: "/vyvoj", en: "/en/development" }, title: "navbar.development" },
            { href: { cs: "/ux-design", en: "/en/ux-design" }, title: "navbar.design" },
            { href: { cs: "/produkt-a-marketing", en: "/en/product-and-marketing" }, title: "navbar.marketing" },
            { href: { cs: "/produkt-a-marketing", en: "/en/product-and-marketing" }, title: "navbar.product" },
        ],
    },
    {
        sectionTitle: "navbar.aboutUs",
        items: [
            { href: { cs: "/reference", en: "/en/reference" }, title: "navbar.reference" },
            { href: { cs: "/nas-tym", en: "/en/our-team" }, title: "navbar.team" },
            { href: { cs: "/kariera", en: "/en/hiring" }, title: "navbar.workWithUs" },
            { href: { cs: "/kontakt", en: "/en/contact" }, title: "navbar.contact" },
        ],
    },
];
