import { useChangeLocale } from "@translations/use-change-locale";
import { TextLink } from "@ui/components/text-link/text-link";
import { cx } from "@uxf/core/utils/cx";
import React, { FC, memo, PropsWithChildren } from "react";

interface LangLinkProps {
    className?: string;
    disabled: boolean;
    onClick: () => void;
    variant: "orange" | "violet";
}

const LangLink: FC<PropsWithChildren<LangLinkProps>> = ({ children, className, disabled, onClick, variant }) => (
    <TextLink
        className={cx(
            "is-disabled:border-rounded-full text-[24px] font-medium is-disabled:pointer-events-none is-disabled:border-b-2 is-disabled:border-uxfOrange is-disabled:font-bold is-disabled:text-uxfOrange is-hoverable:text-uxfOrange lg:pb-1 lg:text-[18px]",
            variant === "orange" ? "text-white" : "text-uxfViolet",
            className,
        )}
        disabled={disabled}
        onClick={onClick}
        variant="custom"
    >
        {children}
    </TextLink>
);

interface Props {
    className?: string;
    variant?: "orange" | "violet";
}

export const LanguageSelector = memo<Props>(({ className, variant = "violet" }) => {
    const { locale, onClick } = useChangeLocale();

    return (
        <div role="listitem" className={className}>
            <LangLink className="mr-3" disabled={locale === "cs"} onClick={onClick} variant={variant}>
                CZ
            </LangLink>
            <LangLink disabled={locale === "en"} onClick={onClick} variant={variant}>
                EN
            </LangLink>
        </div>
    );
});

LanguageSelector.displayName = "LanguageSelector";
