import daktela from "@public/static/caseStudy/daktela-integration/blog_article.jpg";
import jablonec from "@public/static/caseStudy/jablonec/hero.png";
import kultura from "@public/static/landing-pages/4000.png";
import klkImg from "@public/static/caseStudy/kalkulator/blog_article.jpg";
import sousedeImg from "@public/static/caseStudy/sousede/blog_article.jpg";
import sousede2Img from "@public/static/caseStudy/sousede2/blog_article.jpg";
import applicationsImg from "@public/static/caseStudy/applications/applications.png";
import petrBosakImg from "@public/static/story/petr-bosak-blog.jpg";
import martinVikImg from "@public/static/story/martin-vik-blog.jpg";
import petraDolejsovaImg from "@public/static/story/petra-dolejsova-blog.jpg";
import martynaSorokaImg from "@public/static/story/martyna-soroka-blog.jpg";
import budujZnackuImg from "@public/static/landing-pages/blog-buduj-znacku.jpg";
import ceskyRajImg from "@public/static/landing-pages/blog-250ka.jpg";
import ceskyRaj24Img from "@public/static/landing-pages/blog-250ka-24.png";
import karolinaJonasovaImg from "@public/static/story/karolina-jonasova-blog.jpg";
import zazehImg from "@public/static/landing-pages/blog-zazeh.jpg";
import { StaticImageData } from "next/image";
import { HASHTAGS, HashTagType } from "@config/hashtags-config";

export type BlogKeys =
    | "kalkulator"
    | "sousede"
    | "sousede2"
    | "daktela-integration"
    | "petr-bosak"
    | "karolina-jonasova"
    | "petra-dolejsova"
    | "buduj-znacku"
    | "250-cesky.raj"
    | "martin-vik"
    | "applications"
    | "zazeh"
    | "250cr-2024"
    | "martyna-soroka"
    | "365-jablonec"
    | "firemni-kultura";

export interface BlogArticle {
    key: BlogKeys;
    hashtags: HashTagType[];
    img: string | StaticImageData;
    title: string;
    url: string;
}

export const BLOG_ARTICLES: BlogArticle[] = [
    {
        key: "kalkulator",
        hashtags: [HASHTAGS.seo, HASHTAGS.marketing],
        img: klkImg,
        title: "blog.articles.kalkulator.title",
        url: "/blog/pripadova-studie/kalkulator-jak-na-seo",
    },
    {
        key: "petr-bosak",
        hashtags: [HASHTAGS.team],
        img: petrBosakImg,
        title: "blog.articles.petrBosak.title",
        url: "/blog/medailonek/petr-bosak",
    },
    {
        key: "sousede",
        hashtags: [HASHTAGS.uxDesign, HASHTAGS.product, HASHTAGS.development],
        img: sousedeImg,
        title: "blog.articles.sousede.title",
        url: "/blog/pripadova-studie/sousede-prepsani-beziciho-projektu",
    },
    {
        key: "karolina-jonasova",
        hashtags: [HASHTAGS.team],
        img: karolinaJonasovaImg,
        title: "blog.articles.karolinaJonasova.title",
        url: "/blog/medailonek/karolina-jonasova",
    },
    {
        key: "daktela-integration",
        hashtags: [HASHTAGS.development, HASHTAGS.integrations, HASHTAGS.uxDesign],
        img: daktela,
        title: "blog.articles.daktela-integration.title",
        url: "/blog/pripadova-studie/integrace-daktela",
    },
    {
        key: "sousede2",
        hashtags: [HASHTAGS.product, HASHTAGS.uxDesign, HASHTAGS.development, HASHTAGS.integrations],
        img: sousede2Img,
        title: "blog.articles.sousede2.title",
        url: "/blog/pripadova-studie/sousede-digitalizace-distribuce-dokumentu",
    },
    {
        key: "petra-dolejsova",
        hashtags: [HASHTAGS.team],
        img: petraDolejsovaImg,
        title: "blog.articles.petraDolejsova.title",
        url: "/blog/medailonek/petra-dolejsova",
    },
    {
        key: "buduj-znacku",
        hashtags: [HASHTAGS.podcast, HASHTAGS.ceo, HASHTAGS.hiring, HASHTAGS.team],
        img: budujZnackuImg,
        title: "blog.articles.budujZnacku.title",
        url: "/blog/firemni/buduj-znacku",
    },
    {
        key: "250-cesky.raj",
        hashtags: [HASHTAGS.team, HASHTAGS.sport],
        img: ceskyRajImg,
        title: "blog.articles.ceskyRaj.title",
        url: "/blog/firemni/250-ceskym-rajem",
    },
    {
        key: "martin-vik",
        hashtags: [HASHTAGS.team],
        img: martinVikImg,
        title: "blog.articles.martinVik.title",
        url: "/blog/medailonek/martin-vik",
    },
    {
        key: "applications",
        hashtags: [HASHTAGS.webApps, HASHTAGS.mobileApps],
        img: applicationsImg,
        title: "blog.articles.applications.title",
        url: "/blog/tema/pwa-vs-mobilni-aplikace",
    },
    {
        key: "zazeh",
        hashtags: [HASHTAGS.podcast, HASHTAGS.ceo, HASHTAGS.hiring, HASHTAGS.team],
        img: zazehImg,
        title: "blog.articles.zazeh.title",
        url: "/blog/firemni/zazeh",
    },
    {
        key: "250cr-2024",
        hashtags: [HASHTAGS.team, HASHTAGS.sport],
        img: ceskyRaj24Img,
        title: "blog.articles.ceskyRaj24.title",
        url: "/blog/firemni/250-ceskym-rajem-2024",
    },
    {
        key: "martyna-soroka",
        hashtags: [HASHTAGS.team],
        img: martynaSorokaImg,
        title: "blog.articles.martynaSoroka.title",
        url: "/blog/medailonek/martyna-soroka",
    },
    {
        key: "365-jablonec",
        hashtags: [HASHTAGS.product, HASHTAGS.uxDesign],
        img: jablonec,
        title: "blog.articles.365-jablonec.title",
        url: "/blog/pripadova-studie/365-jablonec",
    },
    {
        key: "firemni-kultura",
        hashtags: [HASHTAGS.team, HASHTAGS.sport],
        img: kultura,
        title: "blog.articles.firemni-kultura.title",
        url: "/blog/firemni/firemni-kultura",
    },
];
