import { MOBILE_NAV_MENU } from "@config/nav-menu-config";
import { NavbarMobileButton } from "./navbar-mobile-button";
import { useLocale } from "@translations/locale-context";
import { Icon } from "@ui/components/icon/icon";
import { Logo } from "@ui/components/logo/logo";
import { useButtonProps } from "@uxf/core/hooks/useButtonProps";
import Link from "next/link";
import React, { ButtonHTMLAttributes, Fragment, memo, useRef } from "react";
import { useTranslation } from "next-i18next";
import { LanguageSelector } from "./language-selector";
import { BLOG_ARTICLES } from "@config/blog-config";
import Image from "next/legacy/image";
import { TextLink } from "@ui/components/text-link/text-link";
import { useOnMount } from "@uxf/core/hooks/useOnMount";
import { Dialog } from "@headlessui/react";

interface Props {
    onClose: () => void;
}

export const NavBarMobileMenu = memo<Props>(({ onClose }) => {
    const locale = useLocale();
    const isEn = locale === "en";
    const { t } = useTranslation();
    const ref = useRef<HTMLDivElement>(null);

    useOnMount(() => {
        window.document.body.style.overflow = "hidden";

        const handleMenuHeight = () => {
            const node = ref.current;
            if (node) {
                node.style.height = `${window.visualViewport?.height ?? window.innerHeight}px`;
            }
        };

        const handleKeyUp = (e: KeyboardEvent) => {
            if (e.key === "Escape") {
                e.preventDefault();
                onClose();
            }
        };

        handleMenuHeight();
        window.addEventListener("resize", handleMenuHeight);
        window.addEventListener("keydown", handleKeyUp);
        return () => {
            window.document.body.style.overflow = "unset";
            window.removeEventListener("resize", handleMenuHeight);
            window.removeEventListener("keydown", handleKeyUp);
        };
    });

    const menuBurgerProps = useButtonProps<ButtonHTMLAttributes<HTMLButtonElement>>({
        className: "p-4 m-[-0.5rem] text-white is-hoverable:text-whiteHover transition",
        onClick: onClose,
        title: t("navbar.hideNav"),
    });

    return (
        <Dialog
            as="div"
            className="fixed inset-0 z-50 flex overflow-auto bg-uxfViolet focus:outline-none"
            initialFocus={ref}
            onClose={onClose}
            open
            ref={ref}
            static
        >
            <Dialog.Panel as={Fragment}>
                <div className="size-full shrink-0" role="list">
                    <div className="flex h-full flex-col">
                        <div className="ui-container flex h-24 shrink-0 items-center justify-between">
                            <div className="hidden sm:block">
                                <Logo variant="colorWhite" />
                            </div>
                            <div className="sm:hidden">
                                <Logo variant="colorWhite" isTextHidden />
                            </div>
                            <LanguageSelector variant="orange" className="ml-auto mr-10 mt-1" />
                            <div className="text-right" role="listitem">
                                <button {...menuBurgerProps}>
                                    <Icon name="cross" size={24} />
                                </button>
                            </div>
                        </div>
                        <div className="ui-container mt-8 grid space-y-10 sm:grid-cols-2 sm:space-x-8 sm:space-y-0">
                            {MOBILE_NAV_MENU.map(({ items, sectionTitle }) => (
                                <div key={sectionTitle} className="flex flex-col space-y-3">
                                    <p className="ui-typo-overline-large mb-3 text-white opacity-40">
                                        {t(sectionTitle)}
                                    </p>
                                    {items.map(({ href, title }) => (
                                        <Fragment key={title}>
                                            {href && (
                                                <Link href={href[locale]} passHref legacyBehavior>
                                                    <NavbarMobileButton title={title} />
                                                </Link>
                                            )}
                                        </Fragment>
                                    ))}
                                </div>
                            ))}
                        </div>
                        <div className="mt-12 grow bg-uxfVioletLight pb-16 pt-10">
                            <div className="ui-container">
                                <p className="ui-typo-overline-large mb-6 text-white opacity-40">{t("blog.name")}</p>
                                <div className="grid gap-x-10 gap-y-6 sm:grid-cols-3">
                                    {BLOG_ARTICLES.slice()
                                        .reverse()
                                        .slice(0, 3)
                                        .map(article => (
                                            <div
                                                key={article.key}
                                                className="flex w-full shrink-0 sm:max-w-sm sm:flex-col"
                                            >
                                                <div className="mb-4 mr-6 w-20 shrink-0 sm:mr-0 sm:w-32">
                                                    <Link href={article.url} passHref legacyBehavior>
                                                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                                        <a className="block rounded-inherit outline-none">
                                                            <Image alt="" layout="responsive" src={article.img} />
                                                            <span className="sr-only">{t(article.title)}</span>
                                                        </a>
                                                    </Link>
                                                </div>
                                                <Link href={article.url} passHref legacyBehavior>
                                                    <TextLink variant="white">
                                                        <p className="ui-typo-body">{t(article.title)}</p>
                                                    </TextLink>
                                                </Link>
                                            </div>
                                        ))}
                                </div>
                                <div className="mt-6 text-right">
                                    <TextLink variant="white" underline href={isEn ? "/en/blog" : "/blog"}>
                                        {t("showMore")}
                                    </TextLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Dialog.Panel>
        </Dialog>
    );
});

NavBarMobileMenu.displayName = "NavBarMobileMenu";
