import { SupportedLanguages } from "@config/translation-config";

type HashTag =
    | "analysis"
    | "branding"
    | "business"
    | "ceo"
    | "counselling"
    | "development"
    | "hiring"
    | "integrations"
    | "marketing"
    | "management"
    | "podcast"
    | "product"
    | "seo"
    | "sport"
    | "team"
    | "uiDesign"
    | "uxDesign"
    | "webApps"
    | "mobileApps";

export type HashTagType = {
    href?: Record<SupportedLanguages, string>;
    title: string;
};

export const HASHTAGS: Record<HashTag, HashTagType> = {
    analysis: {
        title: "hashtag.analysis",
    },
    branding: {
        title: "hashtag.branding",
        href: { cs: "/ux-design", en: "/en/ux-design" },
    },
    business: {
        title: "hashtag.business",
    },
    ceo: {
        title: "hashtag.ceo",
    },
    counselling: {
        title: "hashtag.counselling",
        href: { cs: "/it-poradenstvi", en: "/en/cto-as-a-service" },
    },
    development: {
        title: "hashtag.development",
        href: { cs: "/vyvoj", en: "/en/development" },
    },
    hiring: {
        title: "hashtag.hiring",
    },
    integrations: {
        title: "hashtag.integrations",
    },
    marketing: {
        title: "hashtag.marketing",
        href: { cs: "/produkt-a-marketing", en: "/en/product-and-marketing" },
    },
    management: {
        title: "hashtag.management",
    },
    podcast: {
        title: "hashtag.podcast",
    },
    product: {
        title: "hashtag.product",
        href: { cs: "/produkt-a-marketing", en: "/en/product-and-marketing" },
    },
    seo: {
        title: "hashtag.seo",
        href: { cs: "/sluzby/technicke-seo", en: "/en/services/technical-seo" },
    },
    sport: {
        title: "hashtag.sport",
    },
    team: {
        title: "hashtag.team",
        href: { cs: "/nas-tym", en: "/en/our-team" },
    },
    uxDesign: {
        title: "hashtag.uxDesign",
        href: { cs: "/ux-design", en: "/en/ux-design" },
    },
    uiDesign: {
        title: "hashtag.uiDesign",
        href: { cs: "/ux-design", en: "/en/ux-design" },
    },
    webApps: {
        title: "hashtag.webApps",
        href: { cs: "/sluzby/webove-aplikace", en: "/en/services/web-applications" },
    },
    mobileApps: {
        title: "hashtag.mobileApps",
    },
};
