import { NEXT_LOCALE_COOKIE, SupportedLanguages } from "@config/translation-config";
import { Cookie } from "@uxf/core/cookie";
import { useRouter } from "next/router";
import { useCallback } from "react";
import { useLocale } from "./locale-context";

interface ReturnProps {
    locale: SupportedLanguages;
    onClick: () => void;
}

export function useChangeLocale(): ReturnProps {
    const { push, asPath } = useRouter();
    const locale = useLocale();

    const onClick = useCallback(async () => {
        const cookie = Cookie.create();
        cookie.set(NEXT_LOCALE_COOKIE, locale === "cs" ? "en" : "cs");
        await push(asPath, undefined, { locale: locale === "cs" ? "en" : "cs" });
    }, [asPath, locale, push]);

    return {
        locale,
        onClick,
    };
}
